import service from "../utils/request";

//注册
function getRegist(params) {
  return service.post("/planet/account/regist", params);
}
//注册验证码
function getQCode(params) {
  return service.post("/planet/account/code", params);
}
//修改密码验证码
function getPwdcode(params) {
  return service.post("/planet/account/pwdcode", params);
}
// 登录
function getLogin(params) {
  return service.post("/planet/account/login", params);
}
// 找回密码
function getVerifypwdcode(params) {
  return service.post("/planet/account/verifypwdcode", params);
}
// 修改密码
function getpassword(params) {
  return service.post("/planet/account/password", params);
}
// 提交名称
function getNickname(params) {
  return service.put("/planet/player/update/nickname", params, {
    headers: { authorization: sessionStorage.getItem("accessToken") },
  });
}
// 記住密碼接口續期
function getToken(params) {
  return service.post(
    "/planet/account/refresh",
    {},
    {
      headers: { authorization: params },
    }
  );
}

export default {
  getRegist,
  getQCode,
  getPwdcode,
  getLogin,
  getVerifypwdcode,
  getpassword,
  getNickname,
  getToken,
};
