function getTextByStatus(val) {
  switch (val) {
    case 1001:
      return "parameter error";
    case 1002:
      return "token invalid";
    case 1003:
      return "IP exception, please log in again";
    case 1004:
      return "wrong token type";
    case 1005:
      return "no access rights";
    case 1006:
      return "token acquisition failed";
    case 1007:
      return "verification code has expired";
    case 1008:
      return "verification code error";
    case 2000:
      return "account already exists";
    case 2001:
      return "account does not exist";
    case 2002:
      return "wrong account or password";
    case 2003:
      return "abnormal account status";
    case 2004:
      return "account format error";
    case 5001:
      return "getting verification code too frequently";
    case 5002:
      return "verification code has expired";
    case 5003:
      return "verification code error";
    case 7001:
      return "player does not exist";
    case 7002:
      return "nickname already exists";
  }
}
export default {
  getTextByStatus,
};
