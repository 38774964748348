import axios from "axios";
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, //添加于请求路径前
  timeout: 5000,
});
// service.interceptors.request.use(
//   //请求拦截
//   (config) => {
//     // const token = localStorage.getItem("token");

//     // config.baseURL = process.env.VUE_APP_API_BASE_URL;

//     // if (token) {
//     //   config.headers["authorization"] = token;
//     // }

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

/**
 * 是否有用！！
 */
service.interceptors.response.use(
  //对请求回来的数据做统一错误处理
  (response) => {
    const res = response;
    if (res.status !== 200) {
      console.log("0000000000000000", res);
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    if (error.response.status == "403" || error.response.status == "401") {
      location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default service;
