import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
//  这里是引入的
import {
  Button,
  NavBar,
  Icon,
  Cell,
  Field,
  CellGroup,
  CheckboxGroup,
  Checkbox,
  Grid,
  GridItem,
  Overlay,
} from "vant";

import "vant/lib/button/style";

createApp(App, router)
  .use(router)
  .use(NavBar)
  .use(Icon)
  .use(Button)
  .use(Cell)
  .use(Field)
  .use(CellGroup)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Grid)
  .use(GridItem)
  .use(Overlay)
  .mount("#app");
