<template>
  <div class="video-container">
    <video
      :style="fixStyle"
      autoplay
      loop
      muted
      v-on:canplay="canplay"
      id="media"
      v-show="!time"
      poster="../assets/img/lodin.png"
      src="../assets/video/1_x264.mp4"
      :class="inputName"
    ></video>
    <video
      :style="fixStyle"
      autoplay
      loop
      muted
      v-on:canplay="canplay"
      id="med"
      src="../assets/video/2_x264.mp4"
      :class="inputName"
    ></video>
  </div>
</template>

<script>
import "../assets/css/unified.css";
import { reactive, toRefs, onMounted, watch } from "@vue/runtime-core";
import { useRoute } from "vue-router";

export default {
  name: "login",
  props: {},
  setup() {
    const route = useRoute();
    const state = reactive({
      fixStyle: "",
      // 是否重复播放
      time: false,
      autoplay: true,
      state: false,
      auto: true,
      muted: false,
      inputName: "left",
    });
    watch(
      () => route.path,
      () => {
        if (route.path == "/login" || route.path == "/loginFrist") {
          state.inputName = "left";
        } else {
          state.inputName = "right";
        }
      }
    );

    // 背景视频
    const vedioCanPlay = false;

    onMounted(() => {
      // 视频自动播放
      state.autoplay = true;
      if (!state.state) {
        document.getElementById("media").loop = false; // 不设置视频循环播放
        document.getElementById("media").addEventListener(
          "ended",
          () => {
            //该视频播放是否已结束
            state.time = !state.time;
            state.state = true;
            state.autoplay = false;
          },
          false
        );
      }
    });
    return {
      ...toRefs(state),
      //背景视频
      vedioCanPlay,
    };
  },
};
</script>

<style lang="scss" scoped>
// 背景视频
.video-container {
  position: relative;
  height: 100vh;
  background-color: #000511;
  overflow: hidden;
  z-index: -1;
}
#media,
#med {
  object-fit: fill;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
// video 白屏替换
video::-webkit-media-controls {
  display: none !important;
}

.left {
  background-size: 100% 100%;
}

.right {
  background-size: 100% 100%;
  position: relative;
  left: -20%;
}
</style>
