<template>
  <div class="loginFrist">
    <span class="ver">{{ version }}</span>
    <div class="loginType">
      <div class="left"><i>THIRO-PARTY</i></div>
      <div class="right" @click="goLogin"><i>ACCOUNT</i></div>
    </div>
    <div class="clickViews">
      <van-field name="checkboxGroup" label="">
        <template #input>
          <van-checkbox v-model="checked" icon-size="14px" class="checkbox">
          </van-checkbox>
          <p class="checktitle">
            <i class="iBox" @click="protocolPrivacy"
              >views terms and agreements</i
            >
          </p>
        </template>
      </van-field>
    </div>
  </div>
</template>

<script>
import "../assets/css/unified.css";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import { reactive, toRefs, onMounted } from "@vue/runtime-core";

export default {
  name: "login",
  props: {},
  setup() {
    const router = useRouter();
    const state = reactive({
      from: [],
      checked: "",
      version: "1.0.2",
    });
    // 协议指南
    const protocolPrivacy = () => {
      router.push({ path: "/agreement" });
    };
    // 个人账号登录
    const goLogin = () => {
      if (state.checked == "") {
        Toast.fail("You need to read and agree to our Terms before logging in");
        return false;
      }
      router.push({ path: "/loginSecond" });
    };

    onMounted(() => {});

    return {
      ...toRefs(state),
      protocolPrivacy,
      goLogin,
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0px;
  padding: 0px;
  .loginFrist {
    .ver {
      position: absolute;
      bottom: 5%;
      left: 2%;
      color: rgb(114, 114, 114);
    }
    .loginType {
      position: absolute;
      bottom: 40px;
      left: 25%;
      display: flex;
      justify-content: space-between;
      width: 50%;
      color: #ffffff;
      .left,
      .right {
        width: 133px;
        height: 26px;
        line-height: 26px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        font-size: 12px;
      }
      .left {
        background-image: url("../assets/img/thirdParty.png");
      }
      .right {
        background-image: url("../assets/img/account.png");
      }
    }
    .clickViews {
      position: absolute;
      width: 60%;
      height: auto;
      left: 32%;
      bottom: 12.75px;
      overflow: hidden;
      .van-cell {
        background-color: transparent;
      }
      .iBox {
        color: gray;
        font-size: 14px;
        margin-left: 10px;
      }
    }
  }
}
</style>
