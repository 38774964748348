import { createRouter, createWebHashHistory } from "vue-router";
import login from "../views/login.vue";

const routes = [
  { path: "/", redirect: "/login" },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      keepAlive: true, // 组件需要缓存
    },
  },

  // 登录下一步
  {
    path: "/loginFrist",
    name: "loginFrist",
    component: () => import("../views/loginFrist.vue"),
    meta: {
      keepAlive: true, // 组件需要缓存
    },
  },
  // 个人账号登录
  {
    path: "/loginSecond",
    name: "loginSecond",
    component: () => import("../views/loginSecond.vue"),
    meta: {
      keepAlive: true, // 组件需要缓存
    },
  },
  // 注册
  {
    path: "/register",
    name: "register",
    component: () => import("../components/register.vue"),
    meta: {
      keepAlive: true, // 组件需要缓存
    },
  },
  // 注册完成
  {
    path: "/registration",
    name: "registration",
    component: () => import("../components/registration.vue"),
  },
  // 遮罩层
  {
    path: "/giveName",
    name: "giveName",
    component: () => import("../components/giveName.vue"),
  },
  // 更改密码
  {
    path: "/changePassword",
    name: "changePassword",
    component: () => import("../components/changePassword.vue"),
    meta: {
      keepAlive: true, // 组件需要缓存
    },
  },
  // 忘记密码
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: () => import("../components/resetPassword.vue"),
  },
  // 协议
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("../components/agreement.vue"),
  },
  // 背景视频
  {
    path: "/video",
    name: "video",
    component: () => import("../components/backgroundVideo.vue"),
  },
  {
    path: "/end.html",
  },
];

export const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
});
